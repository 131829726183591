// Load Styles
import "../scss/main.scss";

// Load jQuery
import "./SetJqueryGlobal.js";
import $ from "jquery";

import LazyLoad from "vanilla-lazyload";

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: false
});

var lazyLoadInstance = new LazyLoad({});

// handle main nav toggler
$("#toggle").click(function () {
  if ($(this).hasClass("active")) {
    $("#mainNav").addClass("closing");

    setTimeout(function () {
      $("#toggle").removeClass("active");
      $("#mainNav").removeClass("open");
      $(".logo").removeClass("nav_is_open");
      $("#mainNav").removeClass("closing");
    }, 1300);
  } else {
    $(this).addClass("active");
    $("#mainNav").addClass("open");
    $(".logo").addClass("nav_is_open");
  }
});

// handle main nav link should close nav overlay
$("#mainNav li a").on("click", function () {
  $("#toggle").trigger("click");
});

// first screen to content button
var btnToContent = $("#buttonToContent a");

$(window).scroll(function () {
  if ($(window).scrollTop() > 100) {
    btnToContent.addClass("d-none");
  } else {
    btnToContent.removeClass("d-none");
  }
});

// Sticky navbar
// =========================
// Custom function which toggles between sticky class (is-sticky)
var stickyToggle = function (sticky, stickyWrapper, scrollElement) {
  var stickyHeight = sticky.outerHeight();
  var stickyTop = stickyWrapper.offset().top;
  if (scrollElement.scrollTop() >= stickyTop) {
    stickyWrapper.height(stickyHeight);
    sticky.addClass("is-sticky");
  } else {
    sticky.removeClass("is-sticky");
    stickyWrapper.height("auto");
  }
};

// Find all data-toggle="sticky-onscroll" elements
$('[data-toggle="sticky-onscroll"]').each(function () {
  var sticky = $(this);
  var stickyWrapper = $("<div>").addClass("sticky-wrapper"); // insert hidden element to maintain actual top offset on page
  sticky.before(stickyWrapper);
  sticky.addClass("sticky");

  // Scroll & resize events
  $(window).on("scroll.sticky-onscroll resize.sticky-onscroll", function () {
    stickyToggle(sticky, stickyWrapper, $(this));
  });

  // On page load
  stickyToggle(sticky, stickyWrapper, $(window));
});

// Popovers
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
